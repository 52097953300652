import React from "react";

const ArrowRight = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.192 10.442L7.94205 16.692C7.88398 16.7501 7.81504 16.7962 7.73917 16.8276C7.6633 16.859 7.58198 16.8752 7.49986 16.8752C7.41774 16.8752 7.33642 16.859 7.26055 16.8276C7.18468 16.7962 7.11574 16.7501 7.05767 16.692C6.9996 16.634 6.95354 16.565 6.92211 16.4892C6.89069 16.4133 6.87451 16.332 6.87451 16.2499C6.87451 16.1677 6.89069 16.0864 6.92211 16.0105C6.95354 15.9347 6.9996 15.8657 7.05767 15.8077L12.8663 9.99986L7.05767 4.19205C6.9404 4.07477 6.87451 3.91571 6.87451 3.74986C6.87451 3.58401 6.9404 3.42495 7.05767 3.30767C7.17495 3.1904 7.33401 3.12451 7.49986 3.12451C7.66571 3.12451 7.82477 3.1904 7.94205 3.30767L14.192 9.55767C14.2502 9.61572 14.2963 9.68465 14.3277 9.76052C14.3592 9.8364 14.3754 9.91772 14.3754 9.99986C14.3754 10.082 14.3592 10.1633 14.3277 10.2392C14.2963 10.3151 14.2502 10.384 14.192 10.442Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowRight;
