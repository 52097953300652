import React from "react";

const Hamburger = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 16.9434C28 17.2086 27.8946 17.4629 27.7071 17.6505C27.5196 17.838 27.2652 17.9434 27 17.9434H5C4.73478 17.9434 4.48043 17.838 4.29289 17.6505C4.10536 17.4629 4 17.2086 4 16.9434C4 16.6781 4.10536 16.4238 4.29289 16.2363C4.48043 16.0487 4.73478 15.9434 5 15.9434H27C27.2652 15.9434 27.5196 16.0487 27.7071 16.2363C27.8946 16.4238 28 16.6781 28 16.9434ZM5 9.94336H27C27.2652 9.94336 27.5196 9.838 27.7071 9.65047C27.8946 9.46293 28 9.20858 28 8.94336C28 8.67814 27.8946 8.42379 27.7071 8.23625C27.5196 8.04872 27.2652 7.94336 27 7.94336H5C4.73478 7.94336 4.48043 8.04872 4.29289 8.23625C4.10536 8.42379 4 8.67814 4 8.94336C4 9.20858 4.10536 9.46293 4.29289 9.65047C4.48043 9.838 4.73478 9.94336 5 9.94336ZM27 23.9434H5C4.73478 23.9434 4.48043 24.0487 4.29289 24.2363C4.10536 24.4238 4 24.6781 4 24.9434C4 25.2086 4.10536 25.4629 4.29289 25.6505C4.48043 25.838 4.73478 25.9434 5 25.9434H27C27.2652 25.9434 27.5196 25.838 27.7071 25.6505C27.8946 25.4629 28 25.2086 28 24.9434C28 24.6781 27.8946 24.4238 27.7071 24.2363C27.5196 24.0487 27.2652 23.9434 27 23.9434Z"
        fill="#101126"
      />
    </svg>
  );
};

export default Hamburger;
