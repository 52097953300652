import React from "react";

const Crown = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3531 9.82828L10.0296 3.51367L6.70617 9.82828L1.38867 4.1784L4.64062 14.9099C4.66131 14.9781 4.7034 15.0379 4.76067 15.0804C4.81794 15.1229 4.88736 15.1459 4.95867 15.1459H15.1007C15.172 15.1459 15.2414 15.1229 15.2987 15.0804C15.356 15.0379 15.398 14.9781 15.4187 14.9099L18.6707 4.1784L13.3531 9.82828Z"
        fill="#FFE17D"
      />
      <path
        d="M6.70621 9.82957L1.38867 4.17969L4.64062 14.9112C4.66131 14.9794 4.7034 15.0392 4.76068 15.0817C4.81796 15.1242 4.88739 15.1471 4.95871 15.1472H7.54586L6.70621 9.82957Z"
        fill="#FFD164"
      />
      <path
        d="M4.30859 13.8184L4.63992 14.9118C4.66061 14.98 4.7027 15.0398 4.75998 15.0823C4.81726 15.1248 4.88669 15.1477 4.95801 15.1477H15.1C15.1713 15.1477 15.2407 15.1248 15.298 15.0823C15.3552 15.0398 15.3973 14.98 15.418 14.9118L15.7494 13.8184H4.30859Z"
        fill="#FFD164"
      />
      <path
        d="M4.30859 13.8184L4.63992 14.9118C4.66061 14.98 4.7027 15.0398 4.75998 15.0823C4.81726 15.1248 4.88669 15.1477 4.95801 15.1477H7.54516L7.33523 13.8184H4.30859Z"
        fill="#FFC350"
      />
      <path
        d="M10.0301 2.51719C10.4889 2.51719 10.8609 2.1452 10.8609 1.68633C10.8609 1.22746 10.4889 0.855469 10.0301 0.855469C9.57121 0.855469 9.19922 1.22746 9.19922 1.68633C9.19922 2.1452 9.57121 2.51719 10.0301 2.51719Z"
        fill="#FFE17D"
      />
      <path
        d="M10.4455 2.10199C9.98664 2.10199 9.61465 1.73 9.61465 1.27113C9.61465 1.1493 9.64152 1.03395 9.68863 0.929688C9.40023 1.06 9.19922 1.34953 9.19922 1.68656C9.19922 2.14543 9.57121 2.51742 10.0301 2.51742C10.3671 2.51742 10.6566 2.31637 10.787 2.02801C10.6797 2.07671 10.5633 2.10194 10.4455 2.10199Z"
        fill="#FFD164"
      />
      <path
        d="M0.664844 3.51328C1.03203 3.51328 1.32969 3.21562 1.32969 2.84844C1.32969 2.48125 1.03203 2.18359 0.664844 2.18359C0.297661 2.18359 0 2.48125 0 2.84844C0 3.21562 0.297661 3.51328 0.664844 3.51328Z"
        fill="#FFE17D"
      />
      <path
        d="M0.997031 3.18199C0.629922 3.18199 0.332344 2.88441 0.332344 2.5173C0.332344 2.41984 0.353828 2.32754 0.391523 2.24414C0.16082 2.3484 0 2.58 0 2.84965C0 3.21676 0.297578 3.51434 0.664687 3.51434C0.934297 3.51434 1.16594 3.35352 1.2702 3.12281C1.1844 3.16177 1.09126 3.18195 0.997031 3.18199Z"
        fill="#FFD164"
      />
      <path
        d="M19.3348 3.51523C19.7019 3.51523 19.9996 3.21757 19.9996 2.85039C19.9996 2.48321 19.7019 2.18555 19.3348 2.18555C18.9676 2.18555 18.6699 2.48321 18.6699 2.85039C18.6699 3.21757 18.9676 3.51523 19.3348 3.51523Z"
        fill="#FFE17D"
      />
      <path
        d="M19.6689 3.18199C19.3018 3.18199 19.0042 2.88441 19.0042 2.5173C19.0042 2.41984 19.0257 2.32754 19.0634 2.24414C18.8327 2.3484 18.6719 2.58004 18.6719 2.84965C18.6719 3.21676 18.9695 3.51434 19.3366 3.51434C19.6062 3.51434 19.8378 3.35352 19.9421 3.12281C19.8563 3.16177 19.7631 3.18195 19.6689 3.18199Z"
        fill="#FFD164"
      />
    </svg>
  );
};

export default Crown;
